import AB from 'utils/ab_v3'
import { computed } from 'mobx'
import UserStore from 'store/UserStore'

/**
 * traffic광고 노출 실험
 * @description 'a': 광고 미노출, 'b': 광고 노출
 * @type {import("mobx").IComputedValue<"a" | "b">}
 */
export const trafficAdDisplayAB = computed(() => {
  const result = AB(UserStore.farmData.userId, 'bannerBuzzvil1025', [100])
  return result
})

/**
 * 기본 물받기 2배 보상 실험
 * @description 'a': 기존 보상, 'b': 2배 보상 선택 가능
 */
// 서버에도 AB 플래그 존재
export const basicWaterDoubleAB = computed(() => {
  const result = AB(UserStore.farmData.userId, 'basicWaterDouble1113', [100])
  return result
})
